<template>
  <div></div>
</template>
<script>
import { parseJwt } from '@/service/jwt'
export default {
  data() {
    return {}
  },
  async mounted() {
    const { url, token } = this.$route.query
    const path = url ? window.atob(url) : '/'
    const user = parseJwt(token)
    this.$store.commit('SET_TOKEN', { accessToken: token })
    this.$store.commit('SET_USER', user)
    setTimeout(() => {
      this.$router
        .replace({
          path,
        })
        .catch(() => {})
    })
  },
}
</script>
